import { kompackAxiosIns } from '@/libs/axios'

const request = {
  get: async url => {
    const promiseResult = await kompackAxiosIns.get(url)
    return promiseResult ? promiseResult.data : []
  },
  post: async ({ url, body = {} }) => {
    const promiseResult = await kompackAxiosIns.post(url, body)
    return promiseResult ? promiseResult.data : {}
  },
  put: async (url, data) => {
    const promiseResult = await kompackAxiosIns.put(url, data)
    return promiseResult ? promiseResult.data : []
  },
  delete: async url => {
    const promiseResult = await kompackAxiosIns.delete(url)
    return promiseResult ? promiseResult.data : []
  },
}

export default request
