import request from './request'

export default {
  list: async url => {
    const resp = await request.get(url)
    const { meta = {}, data = [] } = resp
    return {
      meta,
      data,
    }
  },
  single: async url => {
    const resp = await request.get(url)
    const { meta = {}, data = {} } = resp
    return {
      meta,
      data,
    }
  },
  insert: async ({ url, body = {} }) => {
    const dataResut = await request.post({
      url,
      body,
    })
    return dataResut
  },
  update_with_body: async ({ url, body = {} }) => {
    const dataResut = await request.put({
      url,
      body,
    })
    return dataResut
  },
  update: async (url, data) => {
    const dataResult = await request.put(url, data)
    return dataResult
  },
  delete: async url => {
    await request.delete(url)
  },
}
